.custom-nav-item {
  margin-right: 30px;
}

.custom-nav-item-1 {
  margin-right: 35px;
}

.custom-padding {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}